<template>
	<div class="h-full flex flex-col" v-if="table">
		<router-view/>
	</div>
</template>

<script type="text/javascript">
	export default {
		components: {
		},
		data(){
			return {
				channel: null
			}
		},
		watch: {
			step(){
				this.handleStep();
			}
		},
		mounted(){
			this.$store.commit('table/SET_TABLE', null);
			this.setupTable();
		},
		methods: {
			setupTable(){
				this.$store.dispatch('table/getTable', {
					uuid: this.$route.params.uuid
				}).then( () => {
					if (this.$route.name === 'chat') return;
					this.setupPusherTable();
				});
			},
			setupPusherTable(){
				if (!this.channel){
					this.channel = this.$store.state.pusher.subscribe(`table-${this.table.uuid}`);
					this.channel.bind('refresh-table', () => {
						this.$store.dispatch('table/getTable', {
							uuid: this.$route.params.uuid
						})
					});
				}
			},
			handleStep(){
				// console.log(`Current step: ${this.step}`);
				if (this.step === 1 && this.$route.name !== 'table-friends'){
					this.$router.push({name: 'table-friends'});
					return;
				}
				if (this.step === 2 && this.$route.name !== 'table-food'){
					this.$router.push({name: 'table-food'});
					return;
				}
				if (this.step === 3 && this.$route.name !== 'table-location'){
					this.$router.push({name: 'table-location'});
					return;
				}
				if (this.step === 4 && this.$route.name !== 'table-price'){
					this.$router.push({name: 'table-price'});
					return;
				}
				if (this.step === 5 && this.$route.name !== 'table-summary'){
					this.$router.push({name: 'table-summary'});
					return;
				}
				if (this.step >= 6 && this.$route.name !== 'table-lobby'){
					this.$router.push({name: 'table-lobby'});
					return;
				}
			}
		},
		computed: {
			pusher(){
				return this.$store.state.pusher;
			},
			step() {
				if (this.$store.state.table.table && this.$store.state.table.table.answers){
					return this.$store.state.table.table.answers.step;
				}
				return -1;
			},
			table(){
				return this.$store.state.table.table;
			}
		}
	}
</script>